@use '/src/styles/variables' as *;

.mat-mdc-slide-toggle {

  // initial state - not disabled, not checked
  &:not(.mat-checked):not(.mat-disabled) {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-bar {
      background-color: rgb(167, 167, 167);
    }
  }

  // checked
  &.mat-checked:not(.mat-disabled) {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-bar {
      background-color: $colorKogenaV2Black;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-thumb {
      background-color: #fff
    }
  }

  // disabled
  &.mat-disabled {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-label {
      cursor: not-allowed;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-thumb-container {
      cursor: inherit; // inherits from .mat-slide-toggle-label
    }
  }

  // disabled, but checked
  &.mat-disabled {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-bar {
      background-color: rgb(167, 167, 167);
      /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      .mat-slide-toggle-thumb {
        background-color: #fff
      }
    }
  }
}

.form-switch {
  &--inline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label.form-label {
      margin-bottom: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
