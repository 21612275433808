@use '/src/styles/variables' as *;

.back-link {
  color: $color--primary;
  font-weight: $font-weight--bold;
  display: inline-flex !important;
  align-items: center;

  &::before {
    content: "";
    background: url('/assets/gfx/long-arrow-left--blueberry.svg') $background;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 8px;
  }

  &:hover {
    &::before {
      background-image: url('/assets/gfx/long-arrow-left--red.svg');
    }
  }

  &.btn {
    font-size: 0.875rem;
    line-height: 1.4;
    transition: none;
    background: #fff;
    border: 1px solid #B9C1C7;
  }
}
