@use '/src/styles/variables' as *;

%custom-scroll {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: $border-radius;
    background: $color--very-light-pink;
  }

  &::-webkit-scrollbar-track {
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background: $color--white;
    border-radius: 10px;
    border: 2px solid $color--very-light-pink;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color--almost-white;
  }
}
