/*
** This file is used to overwrite default deeppurple theme pallet.
*/

@use './mat-color-palettes' as *;
@use '@angular/material' as mat;
@include mat.core();

$kg-material-color-palette--primary: mat.m2-define-palette($kg-material-color-map--primary);
$kg-material-theme: mat.m2-define-light-theme((
  color: (
    primary: $kg-material-color-palette--primary,
    accent: $kg-material-color-palette--primary,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($kg-material-theme);

#backup-files .mat-mdc-radio-button .mdc-radio {
  padding: 0;
}
