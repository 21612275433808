@use '/src/styles/variables' as *;

h1,
.h1 {
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 400;
  margin: 0;
  padding: 0;

  @media (min-width: $md) {
    font-size: 1.5em;
  }
}
