@use '/src/styles/variables' as *;

.grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  &--alignItemsEnd {
    align-items: flex-end;
  }

  &--alignItemsCenter {
    align-items: center;
  }

  &--justifyContentEnd {
    justify-content: flex-end;
  }

  &--justifyContentCenter {
    justify-content: center;
  }

  &--directionRow {
    flex-direction: row;
  }

  &--directionColumn {
    flex-direction: column;
  }

  &--directionRow-tabletUp {
    @media (min-width: $md) {
      flex-direction: row;
    }
  }

  &--directionColumn-tabletUp {
    @media (min-width: $md) {
      flex-direction: column;
    }
  }

  &--directionRow-desktopUp {
    @media (min-width: $lg) {
      flex-direction: row;
    }
  }

  &--directionColumn-desktopUp {
    @media (min-width: $lg) {
      flex-direction: column;
    }
  }

  &--gap {
    width: calc(100% + 8px);
    margin: -4px;

    .grid__item {
      padding: 4px;
    }
  }

  &__item {
    width: 100%;

    &--1of5 {
      width: 20%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 20%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 20%;
        }
      }
    }

    &--1of4 {
      width: 25%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 25%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 25%;
        }
      }
    }

    &--1of3 {
      width: 33.33%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 33.33%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 33.33%;
        }
      }
    }

    &--2of5 {
      width: 40%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 40%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 40%;
        }
      }
    }

    &--1of2 {
      width: 50%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 50%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 50%;
        }
      }
    }

    &--3of5 {
      width: 60%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 60%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 60%;
        }
      }
    }

    &--2of3 {
      width: 66.66%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 66.66%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 66.66%;
        }
      }
    }

    &--3of4 {
      width: 75%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 75%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 75%;
        }
      }
    }

    &--1of1 {
      width: 100%;

      &-tabletUp {
        @media (min-width: $md) {
          width: 100%;
        }
      }

      &-desktopUp {
        @media (min-width: $lg) {
          width: 100%;
        }
      }
    }
  }
}
