@use '/src/styles/variables' as *;

.yellow {
  color: $color-warning;
}

// TODO: keep only one of those classes
.red, .font_error {
  color: $color-error
}
