@use '/src/styles/variables' as *;
@use '/src/styles/extends' as *;

.options {
  position: absolute;
  background-color: $color--white;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: $col__padding;
  z-index: 5;
  display: none;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  font-size: .875em;
  border: 1px solid $color-secondary-3;

  &__item {
    width: 100%;
    padding: 5px;
  }

  &__scroll {
    overflow: auto;
    max-height: 300px;
    overflow-x: hidden;
    @extend %custom-scroll;
  }

  &--active {
    display: block;
    bottom: -10px;
  }
}
