@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;
@use '/src/styles/extends' as *;

.dashboard {
  &__w1 {
    display: flex;
    flex-direction: row;

    &-icon {
      content: "";
      width: 42px;
      height: 42px;
      display: block;
      background: $background;
      margin: 0 7px 0 0;

      &--utilization {
        background-image: url('/assets/gfx/icon__dashboard-utilization.svg');
      }

      &--efficiency {
        background-image: url('/assets/gfx/icon__dashboard-efficiency.svg');
      }

      &--waittime {
        background-image: url('/assets/gfx/icon__dashboard-wait-time.svg');
      }

      &--disconnected {
        background-image: url('/assets/gfx/icon__dashboard-disconnected.svg');
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      width: 100%;
    }

    &-title {
      color: $color--brown-grey;
      font-size: 12px;
      padding: 2px 0;
    }

    &-number {
      color: $color--primary;
      font-weight: $font-weight--medium;
      font-size: 14px;
      padding: 5px 0 0 0;
    }

    &-timestamp {
      font-size: 10px;
      color: #888;
      padding: 5px 0 0 0;
    }
  }

  &__robot {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: auto;
      height: 285px;
    }

    &-controls {
      position: absolute;
      bottom: 15px;
      left: 0;

      &--right {
        left: initial;
        right: 0;
      }
    }
  }

  &__w2 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 130px;
    border-radius: 2.5 * $border-radius;

    &-title {
      font-size: 12px;
      color: $color--brown-grey;
      text-align: center;
      padding-bottom: 10px;
    }

    &-data {
      text-align: center;
      font-size: 24px;
      color: $color--primary;
      font-weight: $font-weight--medium;
      word-break: break-word;
    }

    &-timestamp {
      font-size: 10px;
      color: $color--brown-grey;
      text-align: center;
      padding: 10px 5px 0;
    }
  }

  &__w3 {
    &:last-child {
      margin-right: initial;
    }

    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      column-gap: 2 * $col__padding;
      row-gap: 2 * $col__padding;
    }

    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid $color--very-light-pink;

      span {
        &:first-child {
          color: $color--brown-grey;
          font-size: 12px;
        }

        &:last-child {
          color: $color--primary;
          font-weight: $font-weight--medium;
          font-size: 16px;
        }
      }
    }

    &-bars {
      display: flex;
      justify-content: space-around;
    }

    &-bar {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-singlebar {
      padding: 20px 10px 10px;
      display: flex;
      justify-content: center;

      &-track,
      &-light {
        display: block;
        height: 100px;
        width: 4px;
        border-radius: 8px;
        background: $color--very-light-pink;
        @extend %smooth--bar;
      }

      &-track {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &-light {
        width: 2px;
        height: 0;
        margin-left: 1px;
        align-self: baseline;

        &--red {
          background: $color--danger;
          @include bar__box-shadow($color--danger);
        }

        &--yellow {
          background: $color--warning;
          @include bar__box-shadow($color--warning);
        }

        &--green {
          background: $color--success;
          @include bar__box-shadow($color--success);
        }

        &--cyan {
          background: lighten($color--tealish, 10%);
          @include bar__box-shadow($color--tealish);
        }

        &--blue {
          background: lighten($color--primary, 5%);
          @include bar__box-shadow($color--primary);
        }
      }

      &-title {
        font-size: 12px;
        color: $color--brown-grey;
        text-align: center;
      }

      &-data {
        color: $color--primary;
        font-weight: $font-weight--medium;
        text-align: center;
        font-size: 16px;
        padding: 5px 0;
      }
    }

    &-timestamp {
      font-size: 10px;
      color: $color--brown-grey;
      padding: 0 3px;
      text-align: center;
    }
  }

  &__w4 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    border-radius: 2.5 * $border-radius;

    &-title {
      font-size: 12px;
      color: $color--brown-grey;
      text-align: center;
      padding-bottom: 10px;
    }

    &-data {
      text-align: center;
      font-size: 18px;
      color: $color--primary;
      font-weight: $font-weight--bold;
    }

    &-timestamp {
      font-size: 10px;
      color: $color--brown-grey;
      text-align: center;
      padding: 10px 5px 0;
    }
  }
}
