@use '/src/styles/variables' as *;

.mat-mdc-paginator {
  background: transparent;
}

.mat-mdc-paginator-range-label {
  font-size: 13px;
  color: $color--text--lighter;
}
