@use '/src/styles/variables' as *;

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-label {
  color: $color--primary;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $color--primary;
}
