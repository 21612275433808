@keyframes slide-from-bottom {
  0% {
    transform: translateY(180px);
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  100% {
    transform: translateY(-180px);
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
  }
}
