@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;
@use '/src/styles/extends' as *;

.select-time {


  &::before {
    content: '';
    width: 1em;
    height: 1em;
    display: inline-block;
    background: url('/assets/gfx/icon__clock--blueberry.svg') $background;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(50%, -50%);
    margin-top: -1px;
  }

  &-options {
    @extend %smooth;
    background: $color--white;
    border-radius: $border-radius;
    display: none;
    position: absolute;
    bottom: -10px;
    transform: translateY(100%);
    left: 0;
    z-index: 10000;
    font-size: .875em;
    border: 1px solid $color-secondary-3;

    &--right {
      left: initial;
      right: 0;
    }

    &.active {
      display: flex;
    }

    &__calendar {
      @extend %options;
    }

    &__pick-date {
      @extend %options;
    }

    &__choose-time-range {
      @extend %options;
      width: 170px;
      flex-direction: column;
    }

    &__link {
      position: relative;
      font-weight: $font-weight--medium;
      padding: 2px 25px 2px 0;
      width: 100%;
      @extend %smooth;

      &::after {
        position: absolute;
        top: 50%;
        margin-top: -7px;
        right: 0;
        content: '';
        width: 14px;
        height: 14px;
        display: block;
        background: $background;
        background-image: url('/assets/gfx/icon__checked--blueberry.svg');
        opacity: 0;
      }

      &:hover,
      &--active {
        color: $color--danger;

        &::after {
          opacity: 1;
        }
      }
    }

    &__title {
      color: $color--brown-grey;
      margin-bottom: 16px;
    }

    &__fieldset {
      padding-bottom: 15px;
      flex-direction: column;
    }

    &__button {
      margin-top: 8px;
    }

    &__input {
      box-shadow: none;
      border: 1px solid $color--very-light-pink;
      border-radius: 0;
      padding: 5px 8px;
      width: 170px;
    }

    &__divider {
      display: block;
      width: 1px;
      padding-left: 1px;
      background: $color--very-light-pink;

      &:first-child {
        display: none;
      }
    }
  }
}
