.diode {
  position: relative;
  padding-left: calc(10px + .5em);

  &:before {
    content: "";
    display: block;
    aspect-ratio: 1;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 0;
  }

  &__working:before,
  &__ok:before {
    background: radial-gradient(circle, rgba(56, 142, 60, 1) 0%, rgba(66, 172, 71, 1) 100%);
    box-shadow: 0 0 5px rgba(78, 196, 83, .9);
  }

  &__warning:before {
    background: radial-gradient(circle, rgba(242, 155, 29, 1) 0%, rgba(245, 176, 77) 100%);
    box-shadow: 0 0 5px rgba(245, 176, 77);
  }

  &__error:before {
    background: radial-gradient(circle, rgba(244, 67, 54, 1) 0%, rgba(247, 79, 66, 1) 100%);
    box-shadow: 0 0 5px rgba(249, 90, 78, .9);
  }
}
