@use '/src/styles/variables' as *;

.modal {
  background: white;
  padding: 1em;

  &__header {
    padding-bottom: 8px;
  }

  &__body {
    display: flex;
    flex-direction: column;

    textarea {
      font-weight: $font-weight;
    }
  }

  &__footer {
    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__counter {
    font-size: 12px;
    color: $color--brown-grey;
    align-self: flex-end;
    padding: 16px 0;
  }
}
