.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 0.5em;
  flex-flow: row;

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }
}
