@use '/src/styles/mixins/body-text' as *;

.b20-regular {
  @include b20-regular;
}

.b20-medium {
  @include b20-medium;
}

.b20-bold {
  @include b20-bold;
}

.b18-regular {
  @include b18-regular;
}

.b18-medium {
  @include b18-medium;
}

.b18-bold {
  @include b18-bold;
}

.b16-regular {
  @include b16-regular;
}

.b16-medium {
  @include b16-medium;
}

.b16-bold {
  @include b16-bold;
}

.b14-regular {
  @include b14-regular;
}

.b14-medium {
  @include b14-medium;
}

.b14-bold {
  @include b14-bold;
}

.b13-regular {
  @include b13-regular;
}

.b13-medium {
  @include b13-medium;
}

.b13-bold {
  @include b13-bold;
}

.b11-regular {
  @include b11-regular;
}

.b11-medium {
  @include b11-medium;
}

.b11-bold {
  @include b11-bold;
}

.b10-regular {
  @include b10-regular;
}

.b10-medium {
  @include b10-medium;
}

.b10-bold {
  @include b10-bold;
}
