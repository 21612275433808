@use '/src/styles/mixins' as *;

.mat-mdc-button,
.mat-mdc-raised-button {
  box-shadow: 0 8px 18px rgba(149, 157, 165, 0.2) !important;
  font-size: 13px !important;

  &:focus {
    outline: none !important;
  }
}

.btn__select {
  font-weight: 500;
}
