@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;

fieldset {
  display: flex;
  flex-direction: row;
}

input,
select,
textarea {
  color: $black;
  font-size: .875rem;
  font-weight: $font-weight;
  line-height: 1;
  padding: 0 1.25rem;
  border-radius: $border-radius;
  border: 1px solid $color-secondary-3;
  cursor: pointer;

  &:focus {
    outline: $outline;
  }

  &[type="checkbox"] {
    min-width: initial;
  }
}

input {
  height: 2.5rem;
}

input[type="radio"] {
  margin-right: .5rem;
}

select {
  font-weight: $font-weight--medium;
  color: $color--black;
}

textarea {
  padding: 0.75rem;
  cursor: text;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="url"],
input[type="search"] {
  cursor: text;
}
