@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;

html {
  font-size: 16px;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  background-color: $page-bg;
  color: $black;
  font-size: 1em;
  line-height: 1.4em;
  font-family: $text;
  font-weight: 400;
  min-height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

app-root {
  display: grid;
  min-height: 100vh;
}

router-outlet {
  display: none;
}

.screen-reader-text {
  display: none;
}

// TODO: keep only one of those classes
.kgn-container {
  position: relative;
  margin-left: 1em;
  margin-right: 1em;

  @media (min-width: $md) {
    margin-left: 2em;
    margin-right: 2em;
  }

  @media (min-width: $lg) {
    margin-left: 3em;
    margin-right: 3em;
  }

  @media (min-width: $xxxl) {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}


//new layout 2023
.box {
  @include shadow(.5em);
  padding: 1em;
  background-color: $white;
  border-radius: 1em;

  @media (min-width: $md) {
    // TODO: was 1.25em; which is good?
    padding: 1.5em;
  }

}

.kgn-grid {
  display: grid;

  > * {
    height: 100%;
  }
}

.kgn-flex {
  display: flex;
}

.kgn-flex-direction-column {
  flex-direction: column;
}

.kgn-flex-direction-row {
  flex-direction: row;
}

.kgn-align-start {
  align-items: flex-start;
}

.kgn-align-center {
  align-items: center;
}

.kgn-align-end {
  align-items: flex-end;
}

.kgn-justify-center {
  justify-content: center;
}

.kgn-justify-end {
  justify-content: flex-end;
}

.kgn-flex-direction-column {
  flex-direction: column;
}

.kgn-height-auto {
  height: auto !important;
}

.kgn-tablet-desktop-only {
  @media (max-width: ($md - 1px)) {
    display: none !important;
  }
}

.kgn-md-only {
  @media (max-width: $md) {
    display: none !important;
  }
}

.kgn-mt-1 {
  margin-top: 1em;
}

.kgn-mt-2 {
  margin-top: 2em;
}

.kgn-mb-1 {
  margin-bottom: 1em;
}

.kgn-mb-2 {
  margin-bottom: 2em;
}

.kgn-my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.kgn-py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.kgn-py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.kgn-py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.kgn-gap-1 {
  gap: .5em;
}

.kgn-gap-2 {
  gap: 1em;
}

.kgn-gap-3 {
  gap: 1.5em;
}

.kgn-gap-4 {
  gap: 2em;
}

.kgn-gap-5 {
  gap: 2.5em;
}

.kgn-grid-col-8-4 {
  grid-template-columns: 8fr 4fr;
}

.kgn-box-shadow {
  @include shadow(.5em);
}

@media (min-width: $sm) {
  .kgn-sm-flex {
    display: flex;
  }

  .kgn-sm-justify-space-between {
    justify-content: space-between;
  }

  .kgn-sm-gap-2 {
    gap: 1em
  }
}

@media (min-width: $md) {
  .kgn-md-gap-1 {
    gap: .5em;
  }

  .kgn-md-gap-2 {
    gap: 1em;
  }

  .kgn-md-gap-3 {
    gap: 1.5em;
  }

  .kgn-md-gap-4 {
    gap: 2em;
  }

  .kgn-md-gap-5 {
    gap: 2.5em;
  }

  .kgn-md-grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .kgn-md-grid-col-8-4 {
    grid-template-columns: 8fr 4fr;
  }

  .kgn-md-my-2 {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

@media (min-width: $lg) {
  .kgn-lg-flex-direction-row {
    flex-direction: row;
  }

  .kgn-lg-grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .kgn-lg-grid-col-8-4 {
    grid-template-columns: 8fr 4fr;
  }
}

@media (min-width: $xl) {
  .kgn-xl-grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .kgn-xl-grid-col-7-5 {
    grid-template-columns: 7fr 5fr;
  }
}

// Fix for u-plot bugs breaking layout with invisible absolute positioned elements
.uplot {
  overflow: hidden;
}


// TODO: skasuj gdy yaskawa robot details będzie w nowym widoku
.robot-grid__col--fullWidth .kg-card {
  margin: 1em 0;
}
