@use '/src/styles/variables' as *;

.top-toolbar {
  position: relative;
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  text-align: left;

  &__left,
  &__right {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__menu-button {
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    background: $background;
    background-image: url('/assets/gfx/icon__window--black.svg');
    margin-left: 12px;
    margin-bottom: 6px;

    &-menu {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(calc(100% - 8px));
      z-index: 5;
      background: $color--white;
      padding: $col__padding;
      border-radius: $border-radius;
      min-width: 220px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }

    &-menu-title {
      position: relative;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 12px;
      padding-right: 8px;

      span {
        position: relative;
        z-index: 2;
        background-color: #fff;
        padding-right: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__export-button {
    content: "";
    width: 24px;
    height: 36px;
    display: block;
    background: $background;
    background-image: url('/assets/gfx/icon__export--black.svg');
    margin-left: 16px;
    margin-bottom: 12px;
    outline: none !important;
  }
}

.top-toolbar__fieldset {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    display: block;
    margin: 0 0 0.5em;
    font-size: .8125em;
    line-height: 1;
    color: $color-grey-4;
  }
}
