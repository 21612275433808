@use '/src/styles/extends' as *;

.scroll-field {
  max-height: 400px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  @extend %custom-scroll;
  padding-right: 10px;

  thead {
    th {
      position: sticky;
      top: -1px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 5px 10px -10px black;
    }
  }
}
