@use '/src/styles/variables' as *;
@use '/src/styles/extends' as *;

.calendar {
  &__month {
    color: $color--brown-grey;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__month-control {
    height: 12px;
    width: 12px;
    display: inline-block;
    background: $background;
    cursor: pointer;

    &--prev {
      background-image: url('/assets/gfx/icon__chevron--left.svg');
    }

    &--next {
      background-image: url('/assets/gfx/icon__chevron--right.svg');
    }

    &--disabled {
      opacity: 0.2;
      cursor: default;
    }
  }

  &__dates {
    font-size: 12px;
    color: $color--primary;
    text-align: center;

    thead {
      border: none;
      margin-bottom: 9px;
    }
  }

  &__day-names {
    th:first-of-type {
      background-color: $color--primary;
      color: $color--white;
      border-radius: 5px 5px 0 0;
      padding: 5px;
    }
  }

  &__month-table {
    color: $color--brown-grey;
  }

  &__row {
    &:nth-child(even) {
      background-color: initial;
    }
  }

  &__day {
    font-size: 12px;
    font-weight: $font-weight--medium;
    line-height: 34px;
    text-align: center;

    &.week-number {
      cursor: pointer;
      background-color: $color--opaque-blue-grey;

      &:hover {
        background-color: $color--light-blue-grey;
      }

      &--active {
        color: $color--danger;
      }
    }

    &--in-range {
      background: lighten($color--warning, 20%);
      color: $color--almost-black;
    }

    &--selected-from {
      background-color: $color--warning;
      color: $color--black;
      border-radius: 20px 0 0 20px;
    }

    &--selected-to {
      background-color: $color--warning;
      color: $color--black;
      border-radius: 0 20px 20px 0;
    }
  }

  &__day-inside {
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 20px;
    @extend %smooth;
  }

  &__day-highlight {
    cursor: pointer;

    &:hover {
      background-color: $color--warning;
      color: $color--black;
    }
  }

  &__other-month {
    color: lighten($color--brown-grey, 20%);
  }

  &__current-day {
    color: $color--primary;
    font-weight: $font-weight--bold;
  }
}
