@use '/src/styles/extends' as *;

.menu-list {

  ul,
  &__ul {
    list-style: none;
    padding: 0;
    margin: 0;

    &--scroll {
      overflow: auto;
      max-height: 260px;
      overflow-x: hidden;
      @extend %custom-scroll;
    }
  }

  &__link {
    padding: 5px 0;
    cursor: pointer;
  }
}
