@use '/src/styles/variables' as *;

@mixin _base-font {
  font-family: $base__font-family;
}

@mixin _b20 {
  @include _base-font;
  font-size: 1.25rem;
  line-height: 1.4;
}

@mixin b20-regular {
  @include _b20;
  font-weight: $font-weight;
}

@mixin b20-medium {
  @include _b20;
  font-weight: $font-weight--medium;
}

@mixin b20-bold {
  @include _b20;
  font-weight: $font-weight--bold;
}


@mixin _b18 {
  @include _base-font;
  font-size: 1.125rem;
  line-height: 1.4;
}

@mixin b18-regular {
  @include _b18;
  font-weight: $font-weight;
}

@mixin b18-medium {
  @include _b18;
  font-weight: $font-weight--medium;
}

@mixin b18-bold {
  @include _b18;
  font-weight: $font-weight--bold;
}


@mixin _b16 {
  @include _base-font;
  font-size: 1rem;
  line-height: 1.4;
}

@mixin b16-regular {
  @include _b16;
  font-weight: $font-weight;
}

@mixin b16-medium {
  @include _b16;
  font-weight: $font-weight--medium;
}

@mixin b16-bold {
  @include _b16;
  font-weight: $font-weight--bold;
}


@mixin _b14 {
  @include _base-font;
  font-size: .875rem;
  line-height: 1.4;
}

@mixin b14-regular {
  @include _b14;
  font-weight: $font-weight;
}

@mixin b14-medium {
  @include _b14;
  font-weight: $font-weight--medium;
}

@mixin b14-bold {
  @include _b14;
  font-weight: $font-weight--bold;
}


@mixin _b13 {
  @include _base-font;
  font-size: .8125rem;
  line-height: 1.4;
}

@mixin b13-regular {
  @include _b13;
  font-weight: $font-weight;
}

@mixin b13-medium {
  @include _b13;
  font-weight: $font-weight--medium;
}

@mixin b13-bold {
  @include _b13;
  font-weight: $font-weight--bold;
}


@mixin _b11 {
  @include _base-font;
  font-size: .6875rem;
  line-height: 1.4;
}

@mixin b11-regular {
  @include _b11;
  font-weight: $font-weight;
}

@mixin b11-medium {
  @include _b11;
  font-weight: $font-weight--medium;
}

@mixin b11-bold {
  @include _b11;
  font-weight: $font-weight--bold;
}


@mixin _b10 {
  @include _base-font;
  font-size: .625rem;
  line-height: 1.4;
}

@mixin b10-regular {
  @include _b10;
  font-weight: $font-weight;
}

@mixin b10-medium {
  @include _b10;
  font-weight: $font-weight--medium;
}

@mixin b10-bold {
  @include _b10;
  font-weight: $font-weight--bold;
}
