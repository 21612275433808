@use '/src/styles/variables' as *;

.cols-12 {
  width: 100%;
}

.cols-11 {
  width: calc(100% * 11 / 12);
}

.cols-10 {
  width: calc(100% * 10 / 12);
}

.cols-9 {
  width: calc(100% * 9 / 12);
}

.cols-8 {
  width: calc(100% * 8 / 12);
}

.cols-7 {
  width: calc(100% * 7 / 12);
}

.cols-6 {
  width: calc(100% * 6 / 12);
}

.cols-5 {
  width: calc(100% * 5 / 12);
}

.cols-4 {
  width: calc(100% * 4 / 12);
}

.cols-3 {
  width: calc(100% * 3 / 12);
}

.cols-2 {
  width: calc(100% * 2 / 12);
}

.cols-1 {
  width: calc(100% * 1 / 12);
}

.cols--br {
  border-right: 1px solid $color--light-blue-grey;
}

.cols--bl {
  border-left: 1px solid $color--light-blue-grey;
}
