@use '/src/styles/variables' as *;

input.ng-invalid.ng-touched:not(form) {
  outline: 2px solid $color--danger;
}

td {
  label {
    color: currentColor;
  }

  .mat-mdc-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
  .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
    border-color: currentColor;
  }

  .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
  .mat-radio-button.mat-accent .mat-radio-outer-circle {
    border-color: currentColor;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
    background: currentColor;
  }
}
