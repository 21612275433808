@mixin fontChart() {
  font-family:
    system-ui,
    -apple-system,
    "Segoe UI",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

@mixin textNoWrap() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin parameterColor($color) {
  &::before {
    background: $color;
  }
}

@mixin bar__box-shadow($color) {
  box-shadow: 0 0 6px 1px lighten($color, 10%);
}

@mixin boxShadow() {
  box-shadow: 0 8px 18px rgba(149, 157, 165, 0.2);
}

@mixin bgk-icon($width, $height, $scr) {
  content: "";
  width: $width;
  height: $height;
  background: url($scr) no-repeat center center;
  display: inline-block;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

//new layout 2023
@mixin font($size) {
  font-size: $size;
  line-height: 1.1;
}

@mixin shadow($shadow) {
  box-shadow: 0 0 $shadow rgba(0, 0, 0, .05);
}
