@use '/src/styles/variables' as *;

.paletization {
  &__gfx {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;

    img {
      width: auto;
      height: 285px;
    }
  }

  &__controls {
    &-container {
      padding-bottom: $col__padding;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &-pallets {
      border-top: 1px solid $color--brown-grey;
    }
  }
}

.plc {
  &__flex {
    display: flex;
  }

  &__col {
    float: left;
    padding: 8px 12px;

    .card {
      margin-bottom: 0 !important;
    }
  }

  &__card {
    &-icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-item {
        width: 42px;
        height: 42px;
        margin: 0 10px 0 0;

        &--ref-number {
          background: url('/assets/gfx/icon__plc-ref-number.svg') no-repeat center center;
        }

        &--ref-name {
          background: url('/assets/gfx/icon__plc-ref-number.svg') no-repeat center center;
        }
      }
    }

    &-noicon {
      min-height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 36px;

      &--left {
        align-items: initial;
      }

      &-title {
        color: $color--brown-grey;
        font-size: 0.75em;
      }

      &-data {
        color: $color--primary;
        font-weight: $font-weight--medium;
      }

      &-desc {
        font-size: 0.75em;
        padding: 2px 0;
        color: $color--brown-grey;
      }
    }

    &-settings {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    margin-top: 6px;

    &-container {
      width: fit-content;
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    margin-top: 5px;

    &-icon {
      min-width: 42px;
      width: 42px;
      height: 42px;
      background: no-repeat center center;
      display: inline-block;
      vertical-align: middle;

      &-cartons {
        background-image: url('/assets/gfx/icon__plc-cartons.svg');
      }

      &-cycle {
        background-image: url('/assets/gfx/icon__plc-cycle.svg');
      }

      &-layer {
        background-image: url('/assets/gfx/icon__plc-layer.svg');
      }

      &-pallets {
        background-image: url('/assets/gfx/icon__plc-pallets.svg');
      }

      &-pallet-stacking-time {
        background-image: url('/assets/gfx/icon__plc-pallet-stacking-time.svg');
      }

      &-pallet-collection-time {
        background-image: url('/assets/gfx/icon__plc-pallet-collection-time.svg');
      }

      &-downtime {
        background-image: url('/assets/gfx/icon__plc-downtime.svg');
      }

      &-main-alarm-counter {
        background-image: url('/assets/gfx/icon__plc-main-alarm-counter.svg');
      }
    }

    &-title {
      padding-left: 7px;
      text-transform: uppercase;
      letter-spacing: -0.25px;
    }
  }

  &__columns {
    &-title {
      font-weight: 600;
      color: $color--primary;
      text-transform: uppercase;
      text-align: center;
      padding: 10px 0;
    }

    &-subtitle {
      font-family: $base__font-family;
      font-size: 0.75em;
      color: $color--brown-grey;
      text-align: center;
      margin-bottom: -10px;
    }
  }
}
