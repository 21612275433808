@use '/src/styles/variables' as *;

.sort {
  position: relative;
  cursor: pointer;
  padding-left: 30px !important;

  &:before,
  &:after {
    position: absolute;
    display: block;
    left: 15px;
    content: "";
    width: 8px;
    height: 8px;
    opacity: 0.5;
  }

  &:before {
    top: 50%;
    transform: translateY(-90%);

    background: url('/assets/gfx/icon-sort__up.svg') $background;
  }

  &:after {
    bottom: 50%;
    transform: translateY(90%);
    background: url('/assets/gfx/icon-sort__down.svg') $background;
  }

  &__asc {
    &::before {
      opacity: 1;
    }
  }

  &__desc {
    &::after {
      opacity: 1;
    }
  }
}
