@use '../variables' as *;

.kgn-filters {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: $lg) {
    margin-bottom: 8px;
  }

  .btn__select {
    min-width: 7em;
  }
}

.kgn-filter {
  display: flex;
  justify-content: flex-end;
  flex-flow: column;
  gap: 2px;

  &__header {
    display: block;
    margin-bottom: 10px;
    color: $color-grey-4;
    font-size: 13px;
    line-height: 1.4rem;
  }

  &__error {
    opacity: 0.3;
    border-radius: 0.25rem;
    line-height: 2.4rem;
    padding: 0 1.25rem;
    height: 2.5rem;
    font-weight: 500;
    background-color: #ffffff;
    border: 1px solid #B9C1C7;
    font-size: 0.875em;
  }

  &__dropdown {
    width: 12em;
  }

  &__radio {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    > label {
      display: flex;
      align-items: center;
    }
  }
}
