@use '/src/styles/mixins' as *;

$legendMarkSize: 13px;

.uplot-legend-vertical {
  .uplot {
    .u-legend {
      display: flex !important;
      flex-direction: column;
      margin: 0 auto;
      text-align: left;

      .u-series {
        text-align: center;
      }

      th {
        text-align: right;
      }

      th,
      .u-value {
        min-width: 160px !important;
      }
    }
  }
}

.uplot {
  @include fontChart();
  line-height: 1.5;
  width: 100%;
  margin: 0;

  .u-wrap {
    position: relative;
    user-select: none;
  }

  .u-over,
  .u-under {
    position: absolute;
  }

  .u-under {
    overflow: hidden;
  }

  .u-legend {
    font-size: 14px;
    margin: auto;
    text-align: center;
    user-select: none;
    min-height: 48px;
    position: relative;
    z-index: 1;
  }

  .u-series {
    display: inline-block;
    vertical-align: baseline;
    margin-right: 12px;
    height: 24px;

    &:not(:first-child) {
      cursor: pointer;
    }
  }

  .u-legend th,
  .u-legend td {
    line-height: 20px;
    vertical-align: baseline;
  }

  .u-legend th {
    font-weight: 400;
  }

  .u-legend th > * {
    display: inline-block;
  }

  .u-legend .u-marker {
    width: $legendMarkSize;
    height: $legendMarkSize;
    margin-right: 5px;
    background-clip: content-box !important;
    position: relative;
    top: 1px;
  }

  .u-value {
    min-width: 50px;
    text-align: left;
    font-weight: 500;
  }

  .u-series > * {
    padding: 2px;
  }

  .u-legend .u-off > * {
    opacity: 0.3;
  }

  .u-label {
    &::after {
      content: ":";
      vertical-align: baseline;
    }
  }

  .u-select {
    background: rgba(0, 0, 0, 0.07);
    position: absolute;
    pointer-events: none;
  }

  .u-cursor-x,
  .u-cursor-y {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;
    z-index: 100;
  }

  &.u-hz .u-cursor-x,
  &.u-vt .u-cursor-y {
    height: 100%;
    border-right: 1px dashed #607D8B;
  }

  &.u-hz .u-cursor-y,
  &.u-vt .u-cursor-x {
    width: 100%;
    border-bottom: 1px dashed #607D8B;
  }

  .u-cursor-pt {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
    will-change: transform;
    z-index: 100;
    /*this has to be !important since we set inline "background" shorthand */
    background-clip: content-box !important;
  }

  .u-select.u-off,
  .u-cursor-x.u-off,
  .u-cursor-y.u-off,
  .u-cursor-pt.u-off {
    display: none;
  }

  .bar-mark {
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
  }

  canvas {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  strong {
    font-weight: 500;
  }
}

// ChartTooltip styles
.kg-chart-tooltip {
  @include fontChart();
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3px 6px;

  &__anchor {
    position: absolute;
    width: 1px;
    height: 1px;
    display: block;
  }

  &__overlay {
    display: none;
    position: absolute;
    min-width: 200px;
    max-width: 360px;
    z-index: 2000;

    &--visible {
      display: block;
    }
  }

  &__series {

    &__label,
    &__value,
    &__legend {
      display: inline-block;
      vertical-align: baseline;
    }

    &__label {
      font-weight: 400;
    }

    &__value {
      font-weight: 500;
    }

    &__legend {
      width: $legendMarkSize;
      height: $legendMarkSize;
      margin-right: 2px;
      position: relative;
      top: 2px;
    }
  }

  &--position-top {
    margin: 0 0 20px 0;
  }

  &--position-top-start {
    margin: 0 0 20px 20px;
  }

  &--position-top-end {
    margin: 0 20px 20px 0;
  }

  &--position-bottom {
    margin: 20px 0 0 0;
  }

  &--position-bottom-start {
    margin: 20px 0 0 20px;
  }

  &--position-bottom-end {
    margin: 20px 20px 0 0;
  }

  &--position-right {
    margin: 0 0 0 20px;
  }

  &--position-right-start {
    margin: 20px 0 0 20px;
  }

  &--position-right-end {
    margin: 0 0 20px 20px;
  }

  &--position-left {
    margin: 0 20px 0 0;
  }

  &--position-left-start {
    margin: 20px 20px 0 0;
  }

  &--position-left-end {
    margin: 0 20px 20px 0;
  }
}
