@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;
@use '/src/styles/extends' as *;

.btn,
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: .25rem;
  line-height: 1;
  width: fit-content;
  padding: 0 1.25rem;
  height: 2.5rem;
  text-decoration: none;
  // TODO: new ommits those
  transition: all 300ms ease-in-out;
  cursor: pointer;
  font-weight: 500;
}

.btn {
  // border-radius: .5rem;
  font-size: .875em;
  border: none;

  &.primary {
    background-color: $color-secondary-main;
    color: $white;

    &:hover {
      // TODO: new has: background-color: lighten($color-secondary-main, 10%);
      background-color: lighten($color-secondary-main, 15%);
    }
  }

  &.secondary {
    color: $white;
    background-color: $color-grey-4;

    &:hover {
      background-color: lighten($color-grey-4, 15%);
    }
  }

  &.tertiary {
    padding: 0;
    color: $black;
    font-weight: 700;
  }

  &__select {
    justify-content: space-between;
    gap: 1em;
    min-width: 8em;
    background-color: $white;
    border: 1px solid $color-secondary-3;
    white-space: nowrap;
    padding: 0 1rem 0 1.25rem;

    &::after {
      content: '';
      aspect-ratio: 1;
      width: 1em;
      overflow: hidden;
      transform: rotate(90deg);
      background-size: cover;
      background-size: cover;
      background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.56 19.23C7.47947 19.3007 7.41604 19.3887 7.37446 19.4874C7.33289 19.5861 7.31427 19.693 7.31999 19.8C7.32821 20.0128 7.41351 20.2154 7.56 20.37C7.62884 20.4527 7.71655 20.5177 7.81574 20.5595C7.91494 20.6012 8.02271 20.6186 8.13 20.61C8.23254 20.6153 8.33491 20.5964 8.42875 20.5547C8.52259 20.513 8.60524 20.4497 8.66999 20.37L16.68 12.33H16.74C16.8061 12.2576 16.8564 12.1721 16.8874 12.0791C16.9184 11.9861 16.9295 11.8876 16.92 11.79C16.9318 11.6877 16.9218 11.584 16.8908 11.4858C16.8598 11.3876 16.8084 11.297 16.74 11.22H16.68L8.66999 3.17999C8.60086 3.10559 8.51737 3.04597 8.42456 3.00473C8.33175 2.96348 8.23155 2.94146 8.13 2.93999C8.02353 2.93824 7.91787 2.95867 7.81973 2.99999C7.7216 3.04131 7.63314 3.10261 7.56 3.17999C7.41351 3.33459 7.32821 3.53717 7.31999 3.74999C7.31143 3.85728 7.32876 3.96505 7.37052 4.06424C7.41229 4.16343 7.47727 4.25114 7.56 4.31999L14.94 11.73C14.97 11.73 14.97 11.76 14.97 11.79C14.97 11.7979 14.9668 11.8056 14.9612 11.8112C14.9556 11.8168 14.948 11.82 14.94 11.82L7.56 19.23Z" fill="%2385898C"/></svg>');
    }
  }
  
  /* Old suffixes */

  &--hide {
    min-width: initial;
    width: 36px;
    height: 36px;
    text-align: center;
    background: url('/assets/gfx/icon__angle-down--black.svg') no-repeat center center;
    background-size: 33%;
  }

  &--show {
    min-width: initial;
    width: 36px;
    height: 36px;
    text-align: center;
    background: url('/assets/gfx/icon__angle-up--black.svg') no-repeat center center;
    background-size: 33%;
  }

  &--close {
    min-width: initial;
    width: 36px;
    height: 36px;
    text-align: center;
    background: url('/assets/gfx/icon__close--black.svg') no-repeat center center;
    background-size: 33%;
  }

  &__border {
    border: 1px solid $color--very-light-pink;
    box-shadow: initial;
  }
}

.select-time {
  padding-left: 2em !important;
  box-shadow: none !important;
}

.time-select-aggregated-item--arrow {
  background-color: $white;
  border: 1px solid $color-secondary-3;
}

// @TODO - consolidate .btn and .button styles
button {
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: .2;
    cursor: default;
  }
}

.button {
  font-size: .875rem;
  border: 1px solid $color-secondary-main;
  background-color: $color-secondary-main;
  color: $white;

  &:hover {
    background-color: lighten($color-secondary-main, 15%);
  }

  &--reverse {
    border: 1px solid $color-secondary-main;
    color: $color-secondary-main;
    background-color: $white;

    &:hover {
      background-color: darken($white, 5%);
    }
  }
}
