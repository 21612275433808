@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;

.mat-mdc-table {
  @include boxShadow();
  border-collapse: separate;

  &--no-shadow {
    box-shadow: none;
  }

  .mat-mdc-row {
    height: auto;
  }

  .mat-mdc-cell {
    line-height: 1.1;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    padding: 8px;

    &:first-of-type {
      padding-left: 16px;
    }

    &:last-of-type {
      padding-right: 16px;
    }
  }

  .mat-mdc-header-cell {
    color: $color--black;
    font-size: .8125em;
  }

  .mat-no-data-row {
    text-align: center;
    height: 48px;
    font-style: italic;
    color: $color--opaque-blue-grey;

    .mat-mdc-cell {
      font-size: .8125em;
    }
  }
}
