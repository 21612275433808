.gripper {
  &__gfx {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 230px;
    }
  }
}
