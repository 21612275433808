.align-self__center {
  align-self: center;
}

.u-text--center {
  text-align: center;
}

.u-font-weight--normal {
  font-weight: 400;
}
