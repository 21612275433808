//----------------------------------------------------------------
// Typography
//----------------------------------------------------------------

$text: 'Roboto', sans-serif;
$base__font-family: 'Roboto', sans-serif;
$secondary__font-family: 'Montserrat', sans-serif;

$base__font-size: 14px;
$base__font-size: 13px;

$font-weight--light: 300;
$font-weight: 400;
$font-weight--medium: 500;
$font-weight--bold: 700;
$font-weight--extrabold: 900;


//----------------------------------------------------------------
// Colors
//----------------------------------------------------------------

/* global */
$color--primary: #333b40;
$color--success: #26e567;
$color--success--dark: #388E3C;
$color--warning: #e6af2e;
$color--danger: #dc0d0e;
$color--error: #B71C1C;
$color--white: #fff;
$color--black: #000;

/* ui-specific */
$color--separator: #e3e3e3;
$color--text--light: #888888;
$color--text--lighter: #868686;
$color--text--dark: #333b40;

/* grey scale */
$color--grey--00: #000;
$color--grey--33: #333b40;
$color--grey--5c: #5c6266;
$color--grey--85: #85898c;
$color--grey--99: #999da0;
$color--grey--b5: #b5bab9;
$color--grey--c2: #c2c4c6;
$color--grey--d8: #d8d9d9;
$color--grey--eb: #ebebec;


// legacy color variables
$color--almost-black: #0b1a16;
$color--tealish: #1eb09c;
$color--dark-grey-blue: #35605a;
$color--light-blue-grey: #bbd2e2;
$color--opaque-blue-grey: #bbd2e242;
$color--very-light-pink: #d8d8d8;
$color--brown-grey: #888888;
$color--almost-white: #dddddd;
$colorGreen: #42a150;

$colorKogenaV2Black: $color--primary;
$colorKogenaV2Black--lighter: lighten($colorKogenaV2Black, 15%);
$color--blueberry: $color--primary;
$colorPinkishRed: $color--danger;
$color--pinkish-red: $color--danger;
$color--macaroni-and-cheese: $color--warning;
$color--spearmint: $color--success;
$colorBlack: $color--black;
$colorWhite: $color--white;
$color--v2-black: $color--primary;


$page-bg: #f2f3f3;
$white: #ffffff;
$black: #2D3031;
$action: #B61C1A;
//Secondary Color
$color-secondary-main: #153043;
$color-secondary-3: #B9C1C7;
$color-secondary-4: #8A98A1;
//Working Color
$color-normal: #388E3C;
//$color-reduced-mode: #43a047;
//$color-running: #4aab4e;
//Offline Color
$color-offline: #9E9E9E;
//$color-connector-offline: #bdbdbd;
//warning Color
$color-warning: #ffb300;
//$color-stopped:#FFA000;
//Error Color
$color-error: #D32F2F;
$color-alert: #ED6963;
//$color-emergency-stop:#b71c1c;
//$color-safeguard-stopped:#c62828;
//$color-protective-stop:#D32F2F;
//$color-violation:#e53935;
//$color-fault:#f44336;
//$color-recovery:#ef5350;
//greyscale;
$color-grey-1: #EBEBEC;
$color-grey-3: #B5BAB9;
$color-grey-4: #85898C;
$color-grey-5: #5C6266;


//----------------------------------------------------------------
// Borders
//----------------------------------------------------------------

$border-radius: 4px;
$background: center center no-repeat;

$header__background: rgba(255, 255, 255, 0.95);
$header__shadow: 0 2px 23px 1px rgba(0, 0, 0, 0.1);
$header__height: 46px;

$content__backround-color: #fafafa;
$content__backround-image: linear-gradient(126deg, $content__backround-color #e9ebeb);
$col__padding: 1em;
$spacer: $col__padding;
$container__width: 1664px;

$card__border-radius: $border-radius;
$card__body-padding: .5em;
$card__box-shadow: 0 .5em 1em rgba(149, 157, 165, 0.2);
$card__margin-bottom: 1em;
$card__margin-bottom--big: 18px;
$card__inactive-color: #f8f8f8;

$table__td-even--color: rgba(61, 52, 139, 0.05);
$table__tr--shadow: 0 0 20px 0 rgba(61, 52, 139, 0.25);

$border-radius: 4px;
$background: center center no-repeat;

$header__background: rgba(255, 255, 255, 0.95);
$header__shadow: 0 2px 23px 1px rgba(0, 0, 0, 0.1);
$header__height: 46px;

$forms__shadow: 0 8px 18px rgba(149, 157, 165, 0.2);
$outline: 2px auto lighten($color--primary, 30%);

$kg-card-offset: 8px;


//----------------------------------------------------------------
// Breakpoints
//----------------------------------------------------------------


/* Keep it in sync with header.component.ts */

$xs: 480px; // extra small - old mobile
$sm: 576px; // small - new mobile
$md: 768px; // medium - tablet
$lg: 1024px; // large - tablet large
$xl: 1200px; // extra large
$xxl: 1560px; // extra extra large
$xxxl: 1700px; // extra extra extra large

$kgDashboardThreeColumnsSm: 1344px;  // at this exact length it fits 3 columns
$kgDashboardThreeColumnsLg: 1468px;  // at this length header does not need smaller font


//----------------------------------------------------------------
// Shadows
//----------------------------------------------------------------

$forms__shadow: 0 8px 18px rgba(149, 157, 165, 0.2);
$outline: 2px auto lighten($color--primary, 30%);

$shadow--success: rgba(56, 142, 60, 0.75);
$shadow--active: rgba(229, 38, 47, 0.75);
$shadow--neutral: rgba(51, 59, 64, 0.75);
$warning-icon__shadow--active: rgba(230, 175, 46, 0.75);
$green-icon__shadow--active: rgba(86, 233, 105, 0.5);


//----------------------------------------------------------------
// Z-Index
//----------------------------------------------------------------

$kgzIndexLevelOne: 1;
$kgzIndexLevel5: 5;
$kgzIndexLevelHighest: 10001;
