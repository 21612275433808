@use '/src/styles/variables' as *;

.disabled {
  // TODO: in new it was fade($black, 30%), which would be darker (#c0c0c1)
  color: $color--grey--d8;
  background: inherit;
  cursor: not-allowed !important;

  &:hover {
    color: $color--grey--d8 !important;
  }
}
