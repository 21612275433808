@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;
@use '/src/styles/extends' as *;

.parameter {
  padding-left: 32px;
  padding-right: 22px;

  &::before {
    content: '';
    width: 8px;
    height: 3px;
    display: inline-block;
    background: $color--black;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translate(50%, -50%);
  }

  &--red {
    @include parameterColor($color--danger);
  }

  &--orange {
    @include parameterColor($color--warning);
  }

  &--spearmint {
    @include parameterColor($color--success);
  }

  &--darkgray {
    @include parameterColor($color--dark-grey-blue);
  }

  &--blueberry {
    @include parameterColor($color--primary);
  }

  &--browngray {
    @include parameterColor($color--brown-grey);
  }

  &--notClickable {
    cursor: default;
  }

  &__add {
    &::before {
      width: 12px;
      height: 12px;
      background: url('/assets/gfx/icon__plus.svg') $background;
    }
  }

  &__remove {
    &::after {
      width: 14px;
      height: 12px;
      background: url('/assets/gfx/icon__close--black.svg') $background;
    }
  }

  &-options {
    @extend %smooth;
    background: $color--white;
    border-radius: $border-radius;
    display: none;
    position: absolute;
    box-shadow: $card__box-shadow;
    bottom: -10px;
    transform: translateY(100%);
    left: 0;
    right: -100px;
    flex-direction: column;
    padding: 15px;

    &--right {
      left: initial;
      right: 0;
    }

    &.active {
      display: flex;
    }

    &__title {
      font-size: 14px;
      color: $color--brown-grey;
      font-weight: $font-weight--medium;
      float: right;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &--sort-up {
        &::after {
          @include bgk-icon(15px, 15px, '/assets/gfx/icon__sort-az--up.svg');
        }
      }

      &--sort-down {
        &::after {
          @include bgk-icon(15px, 15px, '/assets/gfx/icon__sort-az--down.svg');
        }
      }
    }

    &__link {
      display: block;
      position: relative;
      font-weight: 500;
      padding: 2px 30px 2px 0;
      width: 100%;
      color: $color--primary;
      box-sizing: border-box;
      overflow: hidden;
      word-break: break-word;
      text-align: left;
      cursor: pointer;
      @extend %smooth;

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        margin-top: -7px;
        right: 8px;
        width: 14px;
        height: 14px;
        background: url('/assets/gfx/icon__checked--blueberry.svg');
        opacity: 0;
      }

      &--active {
        pointer-events: none;
      }

      &:hover,
      &--active {
        color: $color--pinkish-red;

        &::after {
          opacity: 1;
        }
      }
    }
  }
}
