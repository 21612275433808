@use '/src/styles/variables' as *;

.tooltip {
  position: relative;
  display: inline-block;

  &__text {
    font-weight: $font-weight;
    box-shadow: $forms__shadow;
    visibility: hidden;
    width: 150px;
    background-color: $color--very-light-pink;
    color: $color--black;
    text-align: center;
    font-size: 13px;
    padding: 5px 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: $border-radius;
    position: absolute;
    z-index: 1;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color--very-light-pink transparent;
    }
  }

  &:hover {
    .tooltip__text {
      visibility: visible;
    }
  }
}
