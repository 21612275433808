@use '/src/styles/variables' as *;

pre {
  text-align: left !important;
  background-color: rgba(0, 0, 0, 0.02);
  border: 2px dashed $color--danger;
  padding: 4px;
  min-height: 50px;
  min-width: 200px;
  max-height: 250px;
}
