@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;
@use '/src/styles/extends' as *;


.card {
  @include shadow(.5em);
  background-color: $white;
  border-radius: 1em;
  padding: 1em;
  margin: 2px;

  &--picked {
    border: 2px solid $color--brown-grey;
    box-shadow: initial;
  }

  &--mb0 {
    margin-bottom: 0;
  }

  &--blankheader {
    margin-top: 25px;
  }

  &__header {
    font-weight: 400;
    color: $color--primary;
    letter-spacing: 0.5px;
    padding: 0 10px 10px;
    font-size: 14px;

    :not(.card) & {
      font-family: Poppins, Roboto, sans-serif;
    }

    &--line {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0.5em;
    }
  }

  &__body {
    &--inactive {
      background-color: $card__inactive-color;
    }

    &--active {
      background-color: $color--white;
      cursor: pointer;

      &:hover {
        background-color: $color--white;
        cursor: pointer;
      }
    }
  }

  &__scroll {
    overflow-x: hidden;
    padding: 8px 16px 8px 8px;
    @extend %custom-scroll;
  }

  &__menu-bar {
    padding: 8px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    &--left,
    &--right,
    &--fullwidth {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    &--right {
      .top-toolbar__fieldset {
        margin-right: 0;
      }
    }

    &--no-margin-left {
      display: flex;
      margin-left: 0;
    }

    &--left {
      > * {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }

    &--fullwidth {
      width: 100%;
    }

    &-parameters {
      padding-top: $spacer;

      &--hide {
        display: none;
      }

      &--no-margin {
        display: none;
      }
    }
  }

  &__fieldset {
    position: relative;
    margin-bottom: 10px;
  }

  &__fieldset2 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    label {
      margin-left: 10px;
      font-size: $base__font-size;
      margin-bottom: 0;
      color: $color--black;
    }
  }

  &__control {
    color: $color--primary;
    font-weight: $font-weight--light;
    text-transform: uppercase;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: lighten($color--brown-grey, 20%);
      margin: 0 8px;
      box-shadow: $shadow--active;
    }

    &--red {
      &:before {
        background: $color--danger;
        box-shadow: 0 0 6px 1px $shadow--active;
      }
    }

    &--green {
      &:before {
        background: $color--success;
        box-shadow: 0 0 6px 1px lighten($color--success, 5%);
      }
    }

    &--gray {
      &:before {
        background: $color--opaque-blue-grey;
        box-shadow: 0 0 6px 1px lighten($color--opaque-blue-grey, 5%);
      }
    }
  }
}

//////

.kg-card {
  height: 100%;

  &--normal {
    .kg-card-label {
      font-size: 11px;
    }

    .kg-card-label,
    .kg-card-value,
    .kg-card-time,
    .kg-card-diode {
      margin: $kg-card-offset * 0.25 0;
    }

    .kg-card-value {
      font-size: 13px;
    }
  }

  &--medium {
    .kg-card-label {
      font-size: 13px;
    }

    .kg-card-label,
    .kg-card-value,
    .kg-card-time,
    .kg-card-diode {
      margin: $kg-card-offset * 0.5 0;
    }

    .kg-card-value {
      font-size: 16px;
      margin: $kg-card-offset 0;
    }
  }

  &--big {
    .kg-card-label {
      font-size: 13px;
    }

    .kg-card-label,
    .kg-card-value,
    .kg-card-time,
    .kg-card-diode {
      margin: $kg-card-offset 0;
    }

    .kg-card-value {
      font-size: 21px;
      margin: $kg-card-offset 0;
    }
  }
}

.kg-card {
  @include shadow(.5em);
  background-color: $white;
  border-radius: 1em;
  padding: 1em;
  position: relative;

  &--transparent {
    background-color: transparent;
    box-shadow: none;
  }

  @media (min-width: $md) {
    padding: 1.25em;
  }
}

.kg-card__title,
.kg-title {
  @include font(1em);
  font-weight: 700;
  color: $black;
  margin: 0 0 1em;
  display: flex;
  gap: .5rem;
  align-items: center;
}

.kg-card-diode {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.kg-card-value {
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.1;
}

.kg-card-time {
  font-size: 10px;
  color: $color--brown-grey;
}

.kg-card-label {
  font-weight: 300;
  font-size: 11px;
  color: $colorKogenaV2Black--lighter;
}

.kg-card-hint {
  margin: 0 0 0 4px;
  color: $color--primary;
}

//TODO: yaskawa, usuń na nowym widoku
.card .kg-card,
.kg-card .kg-card {
  padding: 0 !important;
  box-shadow: none !important;
}
