@use '/src/styles/variables' as *;

.mat-mdc-tab-group {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    overflow: hidden;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    opacity: 1;
    min-width: auto;
    padding: 0 16px;
    user-select: none;
    background-color: transparent;

    &-active {
      color: $color--v2-black;
    }
  }

  .mat-ink-bar {
    background-color: $color--danger;
  }
}

.mat-mdc-cell {
  font-size: 13px;
}
