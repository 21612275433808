@use '/src/styles/variables' as *;

// Should be changed into card component modifier.
.robot-list {
  .card {
    border: 2px solid transparent;
  }

  .card--picked {
    border: 2px solid $color--brown-grey;
  }
}
