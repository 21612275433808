@use '/src/styles/variables' as *;

.icon-button {
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;

  &:hover {
    color: $color--danger;
  }
}
