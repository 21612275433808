@use '/src/styles/variables' as *;

.table-basic {
  font-size: .75em;
  color: $black;

  &__head,
  &__row {
    display: flex;
    gap: .5em;
    align-items: center;
  }

  &.legend {

    .table-basic__head,
    .table-basic__row {
      display: grid;
    }
  }

  &__head {
    font-weight: 700;

    .legend & {
      > * {
        background-color: $color-grey-1;
        border-radius: .25rem .25rem 0 0;
        padding: .6em 1em .3em;
      }
    }
  }

  &__row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    transition: all ease 0.2s;

    .legend & {
      > * {
        background-color: $color-grey-1;
        border-bottom: none;
        padding: .3em 1em;
      }

      &:last-child > * {
        border-radius: 0 0 .25rem .25rem;
        padding: .3em 1em .6em;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:last-child {
      border-bottom: none;
    }

    &--transparent {
      border-bottom: none !important;
      background-color: transparent !important;
    }

    &--flexStart {
      align-items: flex-start;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: .4em .5em;
    line-height: 1.1;

    &--header {
      font-weight: 700;
    }

    &--textRight {
      text-align: right;
    }

    &--wide {
      flex-grow: 1;
    }

    &--textAlignedVertically {
      display: inline-grid;
      grid-template-columns: max-content max-content;
      align-items: center;
    }
  }
}

.chart-legend {
  display: grid;
  gap: 1em;

  .kg-title {
    display: none;
  }

  @media (min-width: $sm) {
    grid-template-columns: 1fr 1fr;
  }
}
