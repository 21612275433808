@use '/src/styles/variables' as *;

// body {
//   font: 400 13px/ 1 $base__font-family;
//   color: $color--primary;
// }

a {
  // color: $color--primary;
  color: $color-secondary-main;

  &:hover {
    text-decoration: none;
    color: $color--danger;
  }
}

.editable {
  cursor: pointer;
}

//fonts

.font12 {
  font-size: .75em;
  line-height: 1.4;
}

.font14 {
  font-size: .875em;
  line-height: 1.4;
}

.font16 {
  font-size: 1em;
  line-height: 1.4;
}

.font18 {
  font-size: 1.125em;
  line-height: 1.4;
}

.font20 {
  font-size: 1.25em;
  line-height: 1.4;
}

.font22 {
  font-size: 1.375em;
  line-height: 1.4;
}

.font24 {
  font-size: 1.5em;
  line-height: 1.4;
}

.font30 {
  font-size: 1.875em;
  line-height: 1.4;
}

.font36 {
  font-size: 2.25em;
  line-height: 1.4;
}

.font40 {
  font-size: 2.5em;
  line-height: 1.4;
}

.font48 {
  font-size: 3em;
  line-height: 1.4;
}

.font50 {
  font-size: 3.125em;
  line-height: 1.4;
}

.font55 {
  font-size: 3.4375em;
  line-height: 1.4;
}

.font60 {
  font-size: 3.75em;
  line-height: 1.4;
}
