@use '/src/styles/variables' as *;

.mat-mdc-snack-bar-container.mat-snack-bar-top {
  width: 560px;
  max-width: 90vw;
  min-height: auto;
  color: #fff;
  border-radius: 0 0 4px 4px;
  margin-top: 0;
  padding: 16px 24px;
  line-height: 1.2;
  box-shadow:
    0 3px 8px -4px rgb(0 0 0 / 20%),
    0 6px 12px 2px rgb(0 0 0 / 14%),
    0 12px 24px 6px rgb(0 0 0 / 12%);
}

.mat-mdc-snack-bar-container.kg-alert--success > div {
  background: $colorGreen;
}

.mat-mdc-snack-bar-container.kg-alert--error > div {
  background: $color--danger;
}

.mat-mdc-simple-snack-bar {
  font-size: 13px;
}
