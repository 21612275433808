@use '/src/styles/variables' as *;
@use '/src/styles/mixins' as *;

.nav {
  @media (max-width: $lg) {
    display: none;
  }

  &__list {
    white-space: nowrap;
    list-style: none;
    margin: 0;
    padding: 0;

    &-level2 {
      @include boxShadow();
      padding: 0;
      position: absolute;
      display: none;
      flex-direction: column;
      background: $header__background;
      max-width: 200px;

      li {
        padding: 10px 15px;
      }

      &--active {
        display: flex;
      }

      .nav__item .active {
        color: $color--danger;
      }
    }
  }

  &__item {
    display: inline-block;
    margin-right: 12px;
    line-height: 17px;

    @media (max-width: $lg + 1px) {
      display: block;
    }
  }

  &__link {
    display: block;
    height: 46px;
    line-height: 47px;
    font-weight: $font-weight--medium;
    transition: ease 0.175s;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    @media (max-width: $lg) {
      display: none;

      &--mobileVisible {
        display: block;
      }
    }

    &:hover,
    &.active {
      border-bottom-color: $color--danger;
    }

    &--has-child {
      display: flex;
      align-items: center;

      &:after {
        content: "";
        display: block;
        margin-left: 3px;
        width: 13px;
        height: 13px;
        background: url('/assets/gfx/icon__angle-down--black.svg') no-repeat center center;
      }
    }
  }
}

.nav.mat-mdc-tab-nav-bar {
  .mat-mdc-tab-link {
    opacity: 1;
    padding: 0 16px;
    border-width: 2px;
    user-select: none;
    background-color: transparent;
    min-width: initial;
  }

  .nav__link:not(.active) {
    border: 0;
  }

  .nav__link:hover:not(.active) {
    border: 0;
    color: inherit;
  }
}
