@use '/src/styles/variables' as *;

mat-expansion-panel.kgn-information {
  background: inherit;
  border: 1px solid $color--grey--d8;
  border-radius: 8px;
  box-shadow: none !important;

  // hover effect
  &:hover,
  &:hover > * {
    background: #E7E8E8 !important;
    cursor: pointer;
  }

  // font-color
  .mat-expansion-panel-header,
  .mat-expansion-panel-body,
  .mat-expansion-indicator:after {
    color: $color--grey--33;
  }

  .mat-expansion-panel-header {
    .mat-content {
      flex: unset;
    }

    .mat-expansion-indicator {
      margin-left: 14px;
    }
  }

  .expansion-panel-body > * {
    flex: 50%;
  }
}

.no-padding {
  .mat-expansion-panel-body {
    padding: 0;
  }
}
